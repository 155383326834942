import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
// 路由点击两次时报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  //pc路由
  {
    path: "/",
    name: "Home",
    component: () => import("../views/pc/home.vue"),
    children: [],
  },
  //h5路由
  {
    path: "/h5Index",
    name: "h5Index",
    component: () => import("../views/mobel/home.vue"),
    childrern: [],
  },
];

const router = new VueRouter({
  mode: 'hash', // 需要后端支持
  routes,
});

export default router;
