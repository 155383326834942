<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import store from '@/store'
// import Router from 'vue-router'
export default {
  data() {
    return {}
  },
  created() { },
  mounted() {
    // T- 移动端 F- PC端
    if (!this.isMobileUserAgent()) {
      console.log('PC端')
      this.$router.replace('/');
    } else {
      console.log('手机端');
      this.$router.replace('/h5Index');
    }
  },
  methods: {
    isMobileUserAgent() { // 判断是pc端还是h5端
      return /iphone|ipod|android|windows.*phone|blackberry.*mobile/i.test(
        window.navigator.userAgent.toLowerCase()
      );
    },
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
